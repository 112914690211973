import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';

var getOfferPriceAndInstallments = function getOfferPriceAndInstallments() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var title = {
    bold: '',
    text: ''
  };

  var subtitle = '';
  try {
    var installments = input.installments,
      offer = input.offer;
    var newInstallments = !installments || installments <= 0 ? 1 : installments;
    title.text = "".concat(newInstallments, "x de ");
    title.bold = "".concat(currencyFormatter({
      value: offer.price / newInstallments
    }), " sem juros");
    subtitle = "".concat(currencyFormatter({
      value: offer.price
    }), " no pix");
  } catch (_unused) {
    title.text = '';
    title.bold = '';
    subtitle = '';
  }
  return {
    subtitle: subtitle,
    title: title
  };
};
export default getOfferPriceAndInstallments;